import { useContext, useEffect } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { isMobile } from "react-device-detect";

import "./styles/main.scss";
import { MEDIA_FONT_SIZE, MEDIA_SCREEN, USER_ROLES } from "@utils/consts";
import { useRouterPaths } from "@hooks/useRouterPaths";
import { UserContext } from "@contexts/User";
import "react-loading-skeleton/dist/skeleton.css";
import GlobalNotify from "@components/GlobalNotify/GlobalNotify";
import useResize from "@hooks/useResize";
import Root from "@pages/Root";
import LocalStorageService from "@services/localStorage";
import { useLocation } from "react-router-dom";
import { SURVEY } from "@router/consts";
import BroadcastMessagesModal from "@components/BroadcastMessages/BroadcastMessagesModal";
import { useRoutes } from "react-router-dom/dist";

const App = () => {
  const {
    logout,
    profile,
    surveyStatus,
    isMainLoading,
    setHtmlFontSize,
    user,
    setUserRootsCall,
  } = useContext(UserContext);

  // Super action from super admin.
  // Login direct using email
  const search = useLocation().search;
  const refreshToken = new URLSearchParams(search).get("rt");
  if (refreshToken) {
    logout();
    LocalStorageService.setRefreshToken(refreshToken);
    window.location.replace("/");
    return;
  }

  // allbry-app to student re-direct survey-Page.
  const refreshToken_app = new URLSearchParams(search).get("refreshToken");
  const accessToken = new URLSearchParams(search).get("accessToken");
  const surveyConfigId = new URLSearchParams(search).get("surveyConfigId");
  const languageCode = new URLSearchParams(search).get("languageCode");
  if (
    refreshToken_app &&
    accessToken &&
    surveyConfigId !== "null" &&
    languageCode
  ) {
    LocalStorageService.setRefreshToken(refreshToken_app);
    LocalStorageService.setAccessToken(accessToken);
    LocalStorageService.setLanguage(
      languageCode == "en" ? "enUS" : languageCode ?? 'sv'
    );
    if (surveyConfigId == "null") {
      window.location.replace("/");
    } else {
      window.location.replace(
        `/survey?surveyConfigId=${surveyConfigId}&languageCode=en`
      );
    }
    return;
  }

  let pathname = useLocation().pathname;
  const params = useLocation()?.search;
  const searchParams = new URLSearchParams(params);
  // allbry-app dip linking to student re-direct survey-Page.
  const emailClick = searchParams.get('emailClick');

  let isCheckInPublic = false;
  if (pathname === SURVEY) {
    isCheckInPublic = true;
  }

  // Set role -> get roles by role;
  const routers = useRouterPaths(
    profile?.type,
    surveyStatus,
    isMainLoading,
    isCheckInPublic
  );

  useEffect(() => {
    routers.length > 0 && setUserRootsCall(routers);
  }, [routers]);

  let element = useRoutes(routers);

  const { windowWidth } = useResize();

  const setFontSize = (val) => {
    document.documentElement.style.fontSize = `${val}px`;
    setHtmlFontSize(val);
  };

  useEffect(() => {
    if (windowWidth < MEDIA_SCREEN.SM) {
      setFontSize(MEDIA_FONT_SIZE.XS);
    } else if (windowWidth < MEDIA_SCREEN.LG) {
      setFontSize(MEDIA_FONT_SIZE.SM);
    } else {
      setFontSize(MEDIA_FONT_SIZE.BASE);
    }
  }, [windowWidth]);

  if (!(pathname === "/join" || pathname.startsWith('/meet')) && isMobile && window.innerWidth < 1100 && !window.ReactNativeWebView
    && (!pathname.startsWith('/survey') || emailClick)
  ) {
    return (
      <Root.MobileView isLandscapeAvailable={window.innerHeight >= 1100} />
    );
  }

  const isVisibleForCurrentUser = user?.roles.some(
    (role) =>
      role.name === USER_ROLES.ALLBRY_COUNSELLOR_ROLE ||
      role.name === USER_ROLES.COUNSELLOR_ROLE ||
      role.name === USER_ROLES.STUDENT_ROLE
  );

  return (
    <>
      <GlobalNotify />
      <SkeletonTheme highlightColor="var(--colors-milk)" color="#fff">
        {isVisibleForCurrentUser && <BroadcastMessagesModal />}
        {element}
      </SkeletonTheme>
    </>
  );
};

export default App;
