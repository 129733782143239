import { useContext, useMemo } from "react";
import { Outlet, useMatch } from "react-router-dom";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";

import Navbar from "@components/Navbar";
import {
  VIDEO_ROOM,
  APPOINTMENT_DETAIL,
  APPOINTMENTS_BOOKING,
  APPOINTMENTS_FREE_TIME,
} from "@router/consts";
import { AFTER_SURVEY_STEPS } from "@utils/consts";
import { UserContext } from "@contexts/User";
import { AppointmentReportContext } from "@contexts/AppointmentReport";
import { AppointmentReportModal } from "./components";
import styles from "./RootLayout.module.scss";
import InternalReportModal from "@pages/Counsellor/Reports/InternalReportModal";

const RootLayout = () => {
  const { currentUserRoots } = useContext(UserContext);
  const menuItems = useMemo(
    () => currentUserRoots?.[0]?.children?.filter((item) => item?.isMenu),
    [currentUserRoots]
  );
  const isVideoRoom = useMatch(VIDEO_ROOM);
  const isAppointmentDetails = useMatch(APPOINTMENT_DETAIL);
  const isAppointmentBooking = useMatch(APPOINTMENTS_BOOKING);
  const isAppointmentFreeTime = useMatch(APPOINTMENTS_FREE_TIME);

  const { afterSurveyWrapStep, setAfterSurveyWrapStep } =
    useContext(UserContext);
  const { appointmentReportItem, internalReportItem } = useContext(
    AppointmentReportContext
  );

  const stepsWrapHandler = () => {
    if (afterSurveyWrapStep === AFTER_SURVEY_STEPS.FIRST_STEP) {
      setAfterSurveyWrapStep(AFTER_SURVEY_STEPS.SECOND_STEP);
    } else {
      setAfterSurveyWrapStep(null);
    }
  };

  return (
    <div className=" min-h-screen h-full max-h-screen flex flex-row relative">
      {/* <AnimatePresence>
        {!!afterSurveyWrapStep && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                type: "easyInOut",
              },
            }}
            exit={{
              opacity: 0,
            }}
            className="bg-black bg-opacity-80 absolute w-full h-full z-10 cursor-pointer"
            onClick={() => stepsWrapHandler()}
          />
        )}
      </AnimatePresence> */}
      {!isVideoRoom &&
        !(
          isAppointmentDetails &&
          !isAppointmentBooking &&
          !isAppointmentFreeTime
        ) && <Navbar navItems={menuItems} />}
      <div
        className={clsx(
          "flex-1 flex justify-center bg-cararra",
          !isVideoRoom && "p-12",
          styles.rootContainer
        )}
      >
        {menuItems && <Outlet />}
      </div>
      <AppointmentReportModal
        isOpen={!!appointmentReportItem && !afterSurveyWrapStep}
      />
      <InternalReportModal
        isOpen={!!internalReportItem && !afterSurveyWrapStep}
      />
    </div>
  );
};

export default RootLayout;
